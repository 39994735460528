a {
  text-decoration: none;
}
.frameChild {
  position: relative;
  border-radius: var(--br-7xs);
  width: 17.25px;
  height: 19px;
  flex-shrink: 0;
}
.dngThMin,
.polygonParent {
  align-self: stretch;
  display: flex;
}
.dngThMin {
  flex: 1;
  position: relative;
  line-height: 24px;
  align-items: center;
}
.polygonParent {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.giCBn,
.triNghimMin {
  align-self: stretch;
  position: relative;
}
.giCBn {
  font-size: var(--open-sans-h3-size);
  letter-spacing: -0.01em;
  line-height: 44px;
  color: var(--neu-8001);
}
.triNghimMin {
  line-height: 24px;
  color: var(--neu-7001);
}
.plan {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.tiTrios {
  position: relative;
  line-height: 24px;
}
.chevronRightIcon {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}
.buttonDesktop1 {
  border-radius: var(--br-xs);
  border: 1.5px solid var(--brand-400-pri1);
  flex-direction: row;
  padding: var(--padding-2xs) var(--padding-lg);
  gap: var(--gap-7xs);
}
a .buttonDesktop1 {
  line-height: 24px;
  color: rgba(0, 132, 255, 0.904);
  font-weight: bold;
  font-size: large;
}
.buttonDesktop1:hover{
  color: rgba(255, 255, 255, 0.904);
  background-color: rgba(0, 132, 255, 0.904);
}
.buttonDesktop,
.buttonDesktop1,
.planParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.buttonDesktop {
  flex-direction: row;
  text-align: center;
  color: var(--brand-400-pri1);
  cursor: pointer;
}
.planParent {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-xs);
}
.checkmarkCircleIcon {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
}
.phng702 {
  flex: 1;
  position: relative;
  line-height: 24px;
}
.infor3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-4xs) 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-4xs);
}
.span {
  color: var(--brand-400-pri1);
}
.ngy {
  font-family: var(--open-sans-h5);
  color: var(--second-500);
}
.infor3Parent,
.infor9,
.infor9Parent {
  align-items: flex-start;
  justify-content: flex-start;
}
.infor9 {
  width: 376px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-4xs) 0;
  box-sizing: border-box;
  gap: var(--gap-4xs);
  opacity: 0;
}
.infor3Parent,
.infor9Parent {
  flex-direction: column;
}
.infor9Parent {
  display: none;
}
.infor3Parent {
  width: 100%;
  display: flex;
}
.tmHiuThm {
  position: relative;
  line-height: 24px;
  display: none;
  font-family: var(--open-sans-h5);
  color: var(--brand-400-pri1);
  text-align: right;
}
.feature {
  gap: var(--gap-xs);
  color: var(--neu-8001);
}
.feature,
.frameParent,
.plan4 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent {
  padding: var(--padding-sm) 0 0;
  gap: var(--gap-xs);
}
.plan4 {
  border-radius: var(--br-2xl);
  background-color: var(--neu-001);
  box-shadow: 0 4px 24px rgb(0 44 95 / 10%);
  border-top: 24px solid var(--brand-300);
  box-sizing: border-box;
  width: 424px;
  flex-shrink: 0;
  padding: var(--padding-sm);
}
.buttonDesktop2,
.infor121,
.infor31,
.infor91 {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.buttonDesktop2 {
  border-radius: var(--br-xs);
  background-color: var(--brand-400-pri1);
  display: flex;
  padding: var(--padding-2xs) var(--padding-lg);
  gap: var(--gap-7xs);
  text-align: center;
  color: var(--neu-001);
}
.infor121,
.infor31,
.infor91 {
  padding: var(--padding-4xs) 0;
  gap: var(--gap-4xs);
}
.infor31 {
  align-self: stretch;
  display: none;
}
.infor121,
.infor91 {
  width: 100%;
  box-sizing: border-box;
}
.infor91 {
  display: flex;
}
.infor121 {
  display: none;
  opacity: 0;
}
.infor3Group,
.infor9Group,
.plan2 {
  flex-direction: column;
  justify-content: flex-start;
}
.infor9Group {
  display: flex;
  align-items: flex-start;
}
.infor3Group,
.plan2 {
  align-self: stretch;
}
.infor3Group {
  display: flex;
  align-items: flex-start;
}
.plan2 {
  border-radius: var(--br-2xl);
  background-color: var(--neu-001);
  box-shadow: 0 4px 24px rgb(0 44 95 / 10%);
  border-top: 24px solid var(--brand-400-pri1);
  box-sizing: border-box;
  width: 424px;
  flex-shrink: 0;
  padding: var(--padding-sm);
}
.plan2,
.plan3,
.plan4,
.plans {
  display: flex;
  align-items: center;
  width: 32%;
}
.plan3 {
  border-radius: var(--br-2xl);
  background-color: var(--neu-001);
  box-shadow: 0 4px 24px rgb(0 44 95 / 10%);
  border-top: 24px solid var(--brand-500);
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-sm);
  justify-content: flex-start;
}
.plans {
  width: 70%;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  font-size: var(--inter-body-m1-size);
  color: var(--second-400);
  font-family: var(--inter-h41);
}

@media only screen and (max-width: 576px) {
  .plans {
    flex-direction: column;
    width: auto;
    align-items: center;
    gap: 30px;
  }
  .plan4, .plan2, .plan3 {
    width: 80%;
    height: auto;
    align-self: center;

  }
  .frameParent {
    align-self: center;
    width: 100%;
  }
  
}