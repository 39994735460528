.giiPhpTon {
  margin-block-start: 0;
  margin-block-end: 0;
}
.linLcAn {
  margin: 0;
}
.giiPhpTonContainer {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 64px;
  margin-bottom: 20px;
}
.triosGiaiQuyt {
  flex: 1;
  position: relative;
  line-height: 24px;
  width: 100%;
}
.title,
.triosGiaiQuytCacVnWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.triosGiaiQuytCacVnWrapper {
  align-self: center;
  flex-direction: row;
  padding: 0 var(--padding-2xl);
  font-size: 20px;
  color: var(--neu-7001);
  width: 90%;
}
.title {
  width: 80%;
  flex-direction: column;
  z-index: 1;
  text-align: center;
  font-size: var(--inter-h2-size);
  color: var(--neu-8001);
  font-family: var(--inter-h41);
}

@media only screen and (max-width: 576px) {
  .title {
    margin: 0;
    padding: 0;
    width: 100vw;
    gap: 0;
  }
  .giiPhpTonContainer {
    font-size: 30px;
    line-height: 40px;
    text-align: center;
  }
  .triosGiaiQuytCacVnWrapper {
    padding: 16px;
    padding-top: 0px;
  }
}