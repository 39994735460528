.mHnhChild {
  position: absolute;
  top: 0;
  left: 189px;
  width: 1017px;
  height: 538px;
}
.tonCu {
  position: absolute;
  top: 132px;
  left: 403px;
  letter-spacing: -0.01em;
  line-height: 44px;
}
.wifiChild {
  position: absolute;
  height: 40.93%;
  width: 100%;
  top: 54.61%;
  right: 0;
  bottom: 4.46%;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--color-gainsboro-100);
}
.vectorIcon,
.vectorIcon1,
.vectorIcon2,
.vectorIcon3 {
  position: absolute;
  height: 54.62%;
  width: 17.56%;
  top: 0;
  right: 15.08%;
  bottom: 45.38%;
  left: 67.36%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vectorIcon1,
.vectorIcon2,
.vectorIcon3 {
  right: 66.58%;
  left: 15.86%;
}
.vectorIcon2,
.vectorIcon3 {
  height: 4.44%;
  width: 23.06%;
  top: 95.56%;
  bottom: 0;
  left: 10.36%;
}
.vectorIcon3 {
  right: 9.58%;
  left: 67.36%;
}
.wifiItem {
  position: absolute;
  height: 22.63%;
  width: 89.71%;
  top: 63.77%;
  right: 5.14%;
  bottom: 13.6%;
  left: 5.14%;
  border-radius: var(--br-6xs);
  background-color: var(--color-darkslategray-200);
}
.frameChild {
  position: relative;
  width: 4.88px;
  height: 4.88px;
  flex-shrink: 0;
}
.ellipseParent {
  position: absolute;
  height: 100%;
  width: 53.72%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 46.28%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-10xs);
}
.groupChild {
  position: absolute;
  height: 75.82%;
  width: 6.79%;
  top: 12.09%;
  right: 93.21%;
  bottom: 12.09%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.frameParent {
  position: absolute;
  height: 9.95%;
  width: 78.91%;
  top: 70.11%;
  right: 8.02%;
  bottom: 19.94%;
  left: 13.07%;
}
.wifi {
  position: absolute;
  top: 12px;
  left: 48px;
  width: 69px;
  height: 49px;
}
.groupIcon,
.lineIcon {
  position: relative;
  width: 32.19px;
  height: 60px;
  flex-shrink: 0;
}
.lineIcon {
  width: 70px;
  height: 8.66px;
}
.groupParent {
  position: absolute;
  top: 119.63px;
  left: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.frameChild1,
.frameChild2 {
  position: absolute;
  top: 68px;
  left: 107px;
  width: 36.5px;
  height: 44.5px;
}
.frameChild2 {
  left: 20px;
  width: 32px;
}
.wifiParent {
  position: relative;
  width: 165px;
  height: 171px;
  flex-shrink: 0;
}
.mngCcB1 {
  position: relative;
  line-height: 22px;
  font-weight: 600;
}
.mngCcB {
  border-radius: var(--br-2xl);
  background-color: var(--neu-100);
  border: 1px solid var(--neu-300);
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-5xs) var(--padding-6xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.mHnhAdHoc1 {
  position: relative;
  line-height: 22px;
  font-weight: 600;
  color: var(--neu-8001);
}
.mHnhAdHoc {
  border-radius: var(--br-5xl);
  border: 4px solid var(--brand-500);
  display: flex;
  flex-direction: column;
  padding: var(--padding-2xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  color: var(--brand-400-pri1);
}
.groupIcon2 {
  position: relative;
  width: 39px;
  height: 74px;
  flex-shrink: 0;
}
.myChCi {
  margin-block-start: 0;
  margin-block-end: 8px;
}
.chiNhnh {
  margin: 0;
}
.myChCiContainer {
  position: relative;
  line-height: 20px;
}
.groupGroup {
  border-radius: var(--br-md);
  background-color: var(--neu-100);
  border: 1px solid var(--neu-300);
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.frameChild3 {
  position: relative;
  width: 124.72px;
  height: 56.5px;
  flex-shrink: 0;
}
.frameGroup,
.mngCcBParent {
  display: flex;
  justify-content: flex-start;
}
.mngCcBParent {
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-4xs);
  text-align: left;
  color: var(--brand-400-pri1);
}
.frameGroup {
  flex-direction: column;
  align-items: center;
}
.mHnhChi {
  position: relative;
  font-size: var(--inter-body-m1-size);
  line-height: 24px;
  font-family: var(--open-sans-h5);
  text-align: left;
}
.mHnhChiNhnh {
  border-radius: var(--br-7xl);
  border: 4px solid var(--brand-500);
  display: flex;
  flex-direction: column;
  padding: var(--padding-2xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  text-align: center;
}
.cloudIcon {
  position: absolute;
  top: calc(50% - 188.31px);
  left: calc(50% - 114.1px);
  width: 305.59px;
  height: 210.37px;
}
.groupIcon7 {
  position: relative;
  width: 82px;
  height: 118px;
  flex-shrink: 0;
}
.groupParent1 {
  position: absolute;
  top: calc(50% - 152.94px);
  left: calc(50% - 47.21px);
  border-radius: var(--br-md);
  background-color: var(--neu-100);
  border: 1px solid var(--neu-300);
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.groupChild1,
.groupIcon8 {
  position: absolute;
  top: calc(50% + 35.36px);
  left: calc(50% - 97.29px);
  width: 288.8px;
  height: 152.95px;
}
.groupIcon8 {
  top: calc(50% - 115.94px);
  left: calc(50% + 8.49px);
  width: 64.39px;
  height: 69.41px;
}
.cloudParent,
.groupIcon9 {
  position: absolute;
  top: calc(50% - 8.94px);
  left: calc(50% - 191.51px);
  width: 32.26px;
  height: 37.31px;
}
.cloudParent {
  top: calc(50% - 170.5px);
  left: calc(50% - 253.5px);
  width: 383.02px;
  height: 376.63px;
}
.mHnhChiNhnhInner {
  position: relative;
  width: 588px;
  height: 413px;
  flex-shrink: 0;
  text-align: center;
}
.vectorIcon12,
.vectorIcon13,
.vectorIcon14 {
  position: absolute;
  height: 54.62%;
  width: 17.56%;
  top: 0;
  right: 15.09%;
  bottom: 45.38%;
  left: 67.36%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vectorIcon13,
.vectorIcon14 {
  right: 66.59%;
  left: 15.86%;
}
.vectorIcon14 {
  height: 4.44%;
  width: 23.06%;
  top: 95.56%;
  bottom: 0;
  left: 10.35%;
}
.mngCcBGroup {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
  color: var(--brand-400-pri1);
}
.mHnhTon {
  position: relative;
  font-size: var(--inter-body-m1-size);
  line-height: 24px;
  font-family: var(--open-sans-h5);
}
.mHnhChiNhnh1 {
  border-radius: var(--br-7xl);
  border: 4px solid var(--brand-500);
  box-sizing: border-box;
  width: 595px;
  height: 700px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: var(--padding-2xs);
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-3xs);
}
.mHnhAdHocParent {
  position: absolute;
  top: 14px;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-xs);
  text-align: left;
  font-size: var(--open-sans-body-s-size);
  color: var(--neu-8001);
}
.mHnh,
.mHnhItem {
  position: absolute;
  top: 272.66px;
  left: 508.72px;
  width: 261.25px;
  height: 106.67px;
}
.mHnh {
  top: 0;
  left: 0;
  width: 1262px;
  height: 714px;
}
.frameChild39 {
  position: relative;
  width: 91px;
  height: 17.32px;
  flex-shrink: 0;
}
.dLiuKtNiAnTonParent,
.frameParent4,
.hThngKtNiAnTonParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.dLiuKtNiAnTonParent,
.frameParent4 {
  gap: var(--gap-2xl);
}
.frameParent4 {
  position: absolute;
  top: 738px;
  left: 982px;
  flex-direction: column;
  gap: var(--gap-7xs);
  font-size: var(--open-sans-body-s-size);
  color: var(--neu-8001);
  font-family: var(--open-sans-h5);
}
.mHnhParent {
  position: relative;
  width: 1262px;
  height: 786px;
  flex-shrink: 0;
}
.mHnhTngThNgDng {
  background-color: var(--neu-001);
  display: flex;
  flex-direction: column;
  padding: var(--padding-sm);
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--open-sans-h3-size);
  color: var(--brand-500);
  font-family: var(--inter-h41);
}

@media only screen and (max-width: 576px) {
  .mHnhTngThNgDng {
    display: none;
  }
}