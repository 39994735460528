.bgIcon {
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 0;
  width: 1920px;
  height: 696px;
  flex-shrink: 0;
  object-fit: cover;
  z-index: 0;
}
.giiPhpTon {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 64px;
}
.hyGiCu {
  margin-block-start: 0;
  margin-block-end: 8px;
}
.chngTiS {
  margin: 0;
}
.triosGiaiQuytContainer {
  flex: 1;
  position: relative;
  line-height: 24px;
}
.title,
.triosGiaiQuytCacVnWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.triosGiaiQuytCacVnWrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-2xl);
  font-size: var(--inter-body-m1-size);
}
.title {
  width: 872px;
  flex-direction: column;
  gap: var(--gap-2xs);
}
.hVTn {
  position: relative;
  line-height: 22px;
  font-weight: 600;
  color: var(--neu-001);
}
.vuiLngNhp {
  position: relative;
  line-height: 24px;
}
.placeholder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.navigationvariant3Icon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
  opacity: 0;
}
.placeholderParent {
  align-self: stretch;
  border-radius: var(--br-2xs);
  background-color: var(--neu-001);
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-2xs);
  align-items: center;
  justify-content: space-between;
  font-size: var(--inter-body-m1-size);
}
.iconLeft {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.helperText {
  position: relative;
  line-height: 18px;
  font-weight: 500;
}
.helper,
.iconLeftParent {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.iconLeftParent {
  display: flex;
  gap: var(--gap-7xs);
}
.helper {
  align-self: stretch;
  display: none;
  font-size: var(--be-vn-subtitle-small-size);
  font-family: var(--be-vn-subtitle-small);
}
.inputForm {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-7xs);
}
.input,
.inputForm,
.inputForm2,
.inputFormParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.inputFormParent {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-3xs);
}
.input,
.inputForm2 {
  flex-direction: column;
}
.inputForm2 {
  width: 648px;
  gap: var(--gap-7xs);
}
.input {
  gap: var(--gap-xs);
}
.error {
  position: relative;
  font-size: var(--inter-body-m1-size);
  line-height: 24px;
  color: var(--semantic-error-500);
  text-align: center;
  display: none;
  align-items: center;
  justify-content: center;
  width: 648px;
}
.inputParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.downloadFillIcon {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}
.buttonDesktop {
  border-radius: var(--br-xs);
  background-color: var(--brand-400-pri1);
  display: flex;
  flex-direction: row;
  padding: var(--padding-2xs) var(--padding-lg);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
  text-align: center;
  font-size: var(--inter-body-m1-size);
  color: var(--neu-001);
  cursor: pointer;
}
a .buttonDesktop {
  line-height: 24px;
  color: rgba(255, 255, 255, 0.904);
  font-weight: bold;
  font-size: large;
}
.buttonDesktop:hover{
  color: rgba(0, 132, 255, 0.904);
  background-color: aliceblue;
}
.contact2,
.content,
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form {
  width: 648px;
  justify-content: center;
  gap: var(--gap-md);
  text-align: left;
  font-size: var(--open-sans-body-s-size);
  color: var(--neutral-500);
}
.contact2,
.content {
  align-self: stretch;
  justify-content: flex-start;
}
.content {
  padding: var(--padding-3xl) 0;
  gap: var(--gap-md);
  z-index: 1;
}
.contact2 {
  overflow: hidden;
  position: relative;
  text-align: center;
  font-size: var(--inter-h2-size);
  color: var(--neu-001);
  font-family: var(--inter-h41);
}

input {
  border: none;
}

@media only screen and (max-width: 576px) {
  .title{
    width: 100%;
  }
  .triosGiaiQuytCacVnWrapper {
    padding: 8px;
  }
  .form {
    width: 100%;
  }
  .inputParent {
    width: 100%;
  }
  .input {
    width: 100%;
    padding: 8px;
  }
  .inputForm, .inputForm2 {
    width: 95%;
  }
  .inputFormParent {
    flex-direction: column;
  }
  .bgIcon {
    display: none;
  }
  .bgIconmobile {
    position: absolute;
    margin: 0 !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    object-fit: cover;
    z-index: 0;
  }

}
@media only screen and (min-width: 576px) {
  .bgIconmobile {
    display: none;
  }
}
