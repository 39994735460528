.ilus11Icon {
  position: relative;
  width: 70px;
  height: 70px;
  flex-shrink: 0;
}
.ilus11Wrapper {
  border-radius: var(--br-8xl);
  background-color: var(--brand-300);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.sHuLm {
  letter-spacing: -0.01em;
  line-height: 32px;
  width: 100%;
  height: max-content;
}
.readMore,
.sHuLm,
.sHuLm1,
.triosUcTrin {
  align-self: stretch;
  position: relative;
}
.sHuLm1 {
  flex: 1;
  font-size: var(--open-sans-h3-size);
  line-height: 48px;
  display: none;
  font-family: var(--open-sans-h5);
}
.readMore,
.triosUcTrin {
  line-height: 24px;
}
.readMore {
  display: none;
  font-family: var(--open-sans-h5);
  color: var(--brand-400-pri1);
  text-align: left;
  height: 24px;
  flex-shrink: 0;
}
.feature1,
.frameParent,
.triosUcTrinKhaiTrenParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.triosUcTrinKhaiTrenParent {
  align-self: center;
  align-items: flex-start;
  gap: var(--gap-3xs);
  font-size: var(--inter-body-m1-size);
  color: var(--neu-7001);
}
.feature1,
.frameParent {
  align-items: center;
}
.frameParent {
  align-self: center;
  width: 90%;
  height: auto;
  padding: 3%;
}
.feature1 {
  border-radius: var(--br-xl);
  background-color: var(--neu-001);
  box-shadow: 0 4px 20px rgba(0, 75, 133, 0.08);
  width: 97%;
  height: 95%;
  flex-shrink: 0;
  padding-top: 5%;
}
.feature1:hover {
  background-color: rgba(55, 158, 255, 0.904);
  color: aliceblue;
}
.sHuLm3 {
  align-self: stretch;
  flex: 1;
  position: relative;
  font-size: var(--open-sans-h3-size);
  line-height: 48px;
  display: none;
  font-family: var(--open-sans-h5);
  color: var(--neu-8001);
}
.feature9,
.triosUcTrinKhaiTrenGroup {
  align-self: stretch;
  flex-direction: column;
}
.triosUcTrinKhaiTrenGroup {
  height: 40%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  font-size: 74%
}
.feature9 {
  border-radius: var(--br-xl);
  background-color: var(--brand-400-pri1);
  box-shadow: 0 4px 20px rgba(0, 75, 133, 0.08);
  width: 424px;
  flex-shrink: 0;
  padding: var(--padding-lg) var(--padding-sm);
  box-sizing: border-box;
  align-items: center;
  color: var(--neu-001);
}
.feature1Parent,
.feature8,
.feature9,
.frame {
  display: flex;
  justify-content: center;
  gap: var(--gap-xs);
}
.feature8 {
  align-self: stretch;
  border-radius: var(--br-xl);
  background-color: var(--neu-001);
  box-shadow: 0 4px 20px rgba(0, 75, 133, 0.08);
  width: 424px;
  flex-shrink: 0;
  flex-direction: column;
  padding: var(--padding-lg) var(--padding-sm);
  box-sizing: border-box;
  align-items: center;
}
.feature1Parent{
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  height: 100%;
}
.frame {
  width: 70%;
  flex-direction: column;
  z-index: 3;
  text-align: center;
  font-size: var(--inter-sub-2-size);
  color: var(--neu-8001);
  font-family: var(--inter-h41);
}


@media only screen and (max-width: 576px) {
  .frame {
    width: auto;
  }
  .feature1Parent {
    flex-direction: column;
    display: contents;
  }
  .feature1 {
    margin: 8px;
    width: 95%;
    height: 400px;
  }
  
}