.closeIcon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
  border: 1px solid gray;
  border-radius: 50%;
}
.closeWrapper {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.checkmarkCircleIcon {
  position: relative;
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  overflow: hidden;
}
.image {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.giThnhCng {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 40px;
}
.thngTinNg {
  margin-block-start: 0;
  margin-block-end: 8px;
}
.cmNBn {
  margin: 0;
}
.tinNhnCaContainer {
  align-self: stretch;
  position: relative;
  font-size: var(--inter-body-m1-size);
  line-height: 24px;
  color: var(--neu-7001);
}
.content,
.content1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.content {
  align-items: center;
  gap: var(--gap-3xs);
}
.tiTrios {
  flex: 1;
  position: relative;
  line-height: 24px;
}
.downloadFillIcon {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}
.buttonDesktop,
.buttons {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.buttonDesktop {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--brand-400-pri1);
  padding: var(--padding-3xs) var(--padding-lg);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.buttons {
  align-self: stretch;
  align-items: center;
  justify-content: center;
  font-size: var(--inter-body-m1-size);
  color: var(--neu-001);
  cursor: pointer;
}
.contentParent,
.popUp {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1000;
}
.contentParent {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-xs);
}
.popUp {
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: var(--br-xl);
  background-color: #fff;
  box-shadow: 0 4px 24px rgb(0 44 95 / 10%);
  width: 380px;
  overflow: hidden;
  padding: var(--padding-sm) var(--padding-2xs) var(--padding-2xs);
  box-sizing: border-box;
  align-items: center;
  gap: var(--gap-4xs);
  text-align: center;
  font-size: var(--inter-h41-size);
  color: var(--neu-8001);
  transform: translate(-50%, -50%);
  font-family: var(--inter-h41);
  z-index: 12;
}

@media only screen and (max-width: 576px) {
  .popUp {
    width: 100%;
  }
}
