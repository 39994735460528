.footerChild,
.footerTriosChild {
  position: absolute;
  margin: 0 !important;
  z-index: 0;
}
.footerTriosChild {
  height: 23.92%;
  width: 6.26%;
  top: 4.66%;
  right: 82.28%;
  bottom: 71.42%;
  left: 11.47%;
  border-radius: var(--br-2xl);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  opacity: 0.4;
}
.footerChild {
  top: 0px;
  width: 500px;
  opacity: 0.5;
}
.logoTriosIcon {
  position: relative;
  width: 181px;
  height: 60px;
  flex-shrink: 0;
  z-index: 1;
}
.cngTyC,
.mtSnPhm {
  align-self: stretch;
  position: relative;
  line-height: 24px;
}
.cngTyC {
  font-size: var(--inter-h41-size);
  letter-spacing: -0.01em;
  line-height: 40px;
}
.mtSnPhmCaParent {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
}
.phng702Tng {
  align-self: stretch;
  position: relative;
  line-height: 22px;
  font-weight: 600;
}
.infor1 {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.phng702Tng1 {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  font-weight: 500;
  color: aliceblue;
}
.phng702Tng1:hover {
  color: black;
}
.infor1Parent,
.infor2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.infor2 {
  width: 90%;
  font-size: var(--inter-body-m1-size);
}
.infor1Parent {
  align-self: stretch;
  gap: var(--gap-4xs);
  font-size: var(--open-sans-body-s-size);
}
.downloadIcon,
.ionqrCodeSharpIcon {
  position: relative;
  height: 50px;
  flex-shrink: 0;
}
.ionqrCodeSharpIcon {
  border-radius: var(--br-4xs);
  width: 50px;
  overflow: hidden;
}
.downloadIcon {
  width: 173.08px;
  mix-blend-mode: normal;
}
.company,
.ionqrCodeSharpParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.ionqrCodeSharpParent {
  padding-top: 50px;
}
.company {
  width: 35%;
  flex-shrink: 0;
  flex-direction: column;
  gap: var(--gap-md);
  font-family: var(--inter-h41);
}
.mtSnPhm1,
.vTrios {
  position: relative;
  display: inline-block;
  width: 90%;
}
.mtSnPhm1 {
  line-height: 24px;
  opacity: 0;
}
.vTrios {
  font-size: var(--inter-h41-size);
  letter-spacing: -0.01em;
  line-height: 40px;
  font-family: var(--inter-h41);
}
.title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
  width: 100%;
}
.trGip {
  position: relative;
  line-height: 22px;
  font-weight: 600;
  display: inline-block;
  width: 339px;
}
.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-4xs);
  font-family: var(--inter-h41);
  width: 100%;
}
.menu1 {
  width: 20%;
  flex-shrink: 0;
  gap: var(--gap-md);
}
.infor2Parent,
.infor32,
.menu1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.infor32 {
  width: 424px;
  font-size: var(--open-sans-body-s-size);
}
.infor2Parent {
  gap: var(--gap-4xs);
  font-family: var(--inter-h41);
  width: 100%;
}
.frameChild {
  position: relative;
  width: 154px;
  height: 40px;
  flex-shrink: 0;
}
.image182Icon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.bCngThng {
  position: relative;
  width: 200px;
  height: 75.67px;
  flex-shrink: 0;
}
.groupParent {
  flex-direction: row;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.footer,
.infor,
.menu3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.menu3 {
  width: 40%;
  flex-shrink: 0;
  flex-direction: column;
  gap: var(--gap-md);
}
.footer,
.infor {
  flex-direction: row;
  z-index: 2;
  width: 100%;
  column-gap: 30%;
}
.footer {
  align-self: center;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  z-index: 2;
  width: 90%;
  padding-left: 10%;
}
.upChild {
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 0;
  border-radius: var(--br-2xs);
  background-color: var(--brand-300);
  width: 56px;
  height: 56px;
  flex-shrink: 0;
  opacity: 0.5;
  z-index: 0;
}
.chevronLeftIcon {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
  z-index: 1;
}
.up {
  margin: 0 !important;
  position: absolute;
  right: 0;
  top: 70%;
  display: flex;
  flex-direction: row;
  padding: var(--padding-2xs);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  transform: rotate(90deg);
  transform-origin: 0 0;
  z-index: 2;
  transform: rotate(0deg);
}
.copyrightBglobal {
  flex: 1;
  position: relative;
  line-height: 20px;
}
.copyRight,
.footerTrios {
  display: flex;
  align-items: center;
  z-index: 0;
}
.copyRight {
  align-self: stretch;
  background-color: var(--neu-8001);
  flex-direction: row;
  padding: var(--padding-sm) 0;
  justify-content: center;
  z-index: 3;
  text-align: center;
  font-size: var(--open-sans-body-s-size);
  font-family: var(--inter-h41);
}
.footerTrios {
  background-color: var(--brand-500);
  width: 100%;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-align: left;
  font-size: var(--inter-body-m1-size);
  color: var(--neu-001);
  font-family: var(--open-sans-h5);
  z-index: 0;
  padding-top: 5%;
}

.TriosContainer_infor__oseXM {
  flex-direction: column;
  gap: var(--gap-xs);
  z-index: 2;
}

@media only screen and (max-width: 576px) { 
  .footerTrios {
    width: 100%;
  }
  .footer, .infor {
    flex-direction: column;
  }
  .footer {
    padding: 16px;
  }
  .phng702Tng {
    width: 80%;
  }
  .ionqrCodeSharpParent{
    flex-direction: column;
  }
  .copyRight {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 0;
    align-items: center;
  }
  .copyrightBglobal {
    min-width: 375px;
  }
  .company, .menu1, .menu3 {
    width: 100%;
  }
  .up {
    top: 85%
  }

}
