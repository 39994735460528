.bgIcon,
.decorIcon {
  position: absolute;
  top: 0;
  height: 1047px;
}
.bgIcon {
  left: 0;
  margin-left: auto;
  margin-right: auto;
  width: 1920px;
  overflow: hidden;
}
.decorIcon {
  left: calc(50% - 960px);
  width: 1677.97px;
}
.heroSection {
  align-self: stretch;
  position: relative;
  height: 1047px;
  flex-shrink: 0;
  overflow: hidden;
}
.policySection {
  position: sticky;
  background: var(--brand-500);
  height: 1047px;
  left: 1%;
  overflow: scroll;
  overflow-x: hidden;
  padding: 50px;
}
.tnhNngChild {
  position: absolute;
  margin: 0 !important;
  top: -192px;
  left: 573px;
  width: 1347px;
  height: 1683.53px;
  flex-shrink: 0;
  opacity: 0.5;
  z-index: 0;
}
.illusIcon {
  position: relative;
  width: 1040px;
  height: 600px;
  flex-shrink: 0;
  display: none;
  z-index: 2;
}
.tnhNng {
  width: 100%;
  align-self: stretch;
  background-color: var(--neu-100);
  display: flex;
  flex-direction: column;
  padding-top:2%;
  padding-bottom: 2%;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-5xl);
}

.cnSd {
  width: 100%;
  align-self: stretch;
  background-color: var(--neu-100);
  display: flex;
  flex-direction: column;
  padding-top:7%;
  padding-bottom: 2%;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-5xl);
}
.mHnhChild {
  top: -217.35px;
  left: 152.86px;
  border-radius: var(--br-sm);
  width: 77.75px;
  height: 86.11px;
  opacity: 0.3;
  z-index: 0;
}
.decorIcon1,
.mHnhChild,
.mHnhItem {
  position: absolute;
  margin: 0 !important;
  flex-shrink: 0;
}
.mHnhItem {
  top: -1107.4px;
  left: 521.46px;
  border-radius: var(--br-xs);
  width: 51.26px;
  height: 56.73px;
  opacity: 0.3;
  z-index: 1;
}
.decorIcon1 {
  top: -814.96px;
  left: calc(50% - 2441.48px);
  width: 3686px;
  height: 3686px;
  display: none;
  z-index: 2;
}
.giiPhpTon {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 64px;
}
.triosGiaiQuyt {
  flex: 1;
  position: relative;
  line-height: 24px;
}
.title,
.triosGiaiQuytCacVnWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.triosGiaiQuytCacVnWrapper {
  align-self: center;
  flex-direction: row;
  font-size: 20px;
  color: var(--neu-7001);
  width: 90%;
}
.title {
  width: 872px;
  flex-direction: column;
  gap: var(--gap-2xs);
  z-index: 3;
}
.frameChild {
  position: absolute;
  margin: 0 !important;
  top: -145.51px;
  left: -199.51px;
  border-radius: var(--br-4xl);
  width: 147.91px;
  height: 147.91px;
  flex-shrink: 0;
  opacity: 0.5;
  z-index: 0;
}
.ilus3Icon {
  position: relative;
  width: 70px;
  height: 70px;
  flex-shrink: 0;
}
.ilus3Wrapper {
  border-radius: var(--br-8xl);
  background-color: var(--brand-300);
  display: flex;
  flex-direction: row;
  padding: var(--padding-xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.feature1:hover {
  background-color: rgba(44, 153, 255, 0.904);
  color: aliceblue;
}
.sHuLm {
  letter-spacing: -0.01em;
  line-height: 32px;
}
.readMore,
.sHuLm,
.sHuLm1,
.triosUcTrin {
  align-self: stretch;
  position: relative;
}
.sHuLm1 {
  flex: 1;
  font-size: var(--open-sans-h3-size);
  line-height: 48px;
  display: none;
  font-family: var(--open-sans-h5);
}
.readMore,
.triosUcTrin {
  line-height: 24px;
}
.readMore {
  display: none;
  font-family: var(--open-sans-h5);
  color: var(--brand-400-pri1);
  text-align: left;
  height: 24px;
  flex-shrink: 0;
}
.frameParent,
.triosUcTrinKhaiTrenParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.triosUcTrinKhaiTrenParent {
  align-items: flex-start;
  gap: var(--gap-3xs);
  font-size: var(--inter-body-m1-size);
  color: var(--neu-7001);
}
.frameParent {
  align-items: center;
  gap: var(--gap-4xs);
}
.feature1,
.sHuLm3 {
  align-self: stretch;
  flex: 1;
}
.feature1 {
  border-radius: var(--br-xl);
  background-color: var(--neu-001);
  box-shadow: 0 4px 20px rgba(0, 75, 133, 0.08);
  display: flex;
  flex-direction: column;
  padding: var(--padding-lg) var(--padding-sm);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
  z-index: 1;
}
.sHuLm3 {
  position: relative;
  font-size: var(--open-sans-h3-size);
  line-height: 48px;
  display: none;
  font-family: var(--open-sans-h5);
  color: var(--neu-8001);
}
.feature7,
.triosUcTrinKhaiTrenGroup {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.triosUcTrinKhaiTrenGroup {
  align-items: flex-start;
  gap: var(--gap-3xs);
  font-size: 74%;
}
.feature7 {
  flex: 1;
  border-radius: var(--br-xl);
  background-color: var(--brand-400-pri1);
  box-shadow: 0 4px 20px rgba(0, 75, 133, 0.08);
  padding: var(--padding-lg) var(--padding-sm);
  align-items: center;
  gap: var(--gap-xs);
  z-index: 2;
  color: var(--neu-001);
}
.traoIThng {
  margin-block-start: 0;
  margin-block-end: 8px;
}
.nhiuCQuan {
  margin: 0;
}
.feature8,
.feature9 {
  flex: 1;
  border-radius: var(--br-xl);
  background-color: var(--neu-001);
  box-shadow: 0 4px 20px rgba(0, 75, 133, 0.08);
  display: flex;
  flex-direction: column;
  padding: var(--padding-lg) var(--padding-sm);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.feature8 {
  align-self: stretch;
  z-index: 3;
}
.feature9 {
  z-index: 0;
}
.angPhtTrin {
  position: relative;
  line-height: 22px;
  font-weight: 600;
}
.angPhtTrinWrapper,
.feature9Parent {
  flex-direction: row;
  align-items: flex-start;
}
.angPhtTrinWrapper {
  margin: 0 !important;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 0 0 var(--br-md);
  background-color: var(--second-400);
  display: flex;
  padding: var(--padding-6xs) var(--padding-4xs);
  justify-content: flex-start;
  z-index: 1;
  font-size: var(--open-sans-body-s-size);
  color: var(--neu-001);
}
.feature9Parent {
  width: 312px;
  flex-shrink: 0;
  position: relative;
  gap: var(--gap-xs);
  z-index: 4;
}
.feature9Parent,
.mHnhTngThNgDngParent,
.polygonParent {
  display: flex;
  justify-content: flex-start;
}
.polygonParent {
  width: 70%;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  gap: var(--gap-xs);
}
.mHnhTngThNgDngParent {
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-5xl);
  z-index: 2;
  font-size: var(--inter-sub-2-size);
}
.decor1Icon {
  position: absolute;
  margin: 0 !important;
  top: -2.98px;
  left: 1448.47px;
  width: 290.03px;
  height: 290.03px;
  flex-shrink: 0;
  z-index: 5;
}
.mHnh,
.title1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.mHnh {
  width: 100%;
  align-self: center;
  background-color: var(--neu-001);
  padding-top: 7%;
  padding-bottom: 2%;
  align-items: center;
  position: relative;
  gap: var(--gap-5xl);
}
.title1 {
  width: 70%;
  align-items: flex-start;
  gap: var(--gap-2xs);
  z-index: 0;
}
.cngNghSDngChild,
.cngNghSDngItem {
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 1494px;
  width: 322.99px;
  height: 797.77px;
  flex-shrink: 0;
  z-index: 2;
}
.cngNghSDngItem {
  top: 76.24px;
  left: 442.91px;
  border-radius: var(--br-lg);
  width: 100.89px;
  height: 106.31px;
  opacity: 0.3;
  z-index: 3;
}
.homepage3,
.plan,
.title2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.title2 {
  width: 90%;
  align-items: center;
  gap: var(--gap-2xs);
}
.homepage3,
.plan {
  background-color: var(--neu-001);
}
.plan {
  padding-top: 6%;
  padding-bottom: 2%;
  align-items: center;
  gap: var(--gap-5xl);
  z-index: 1;
  width: 100%;
}
.homepage3 {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1920px;
  overflow: hidden;
  align-items: flex-start;
  text-align: center;
  font-size: var(--inter-h2-size);
  color: var(--neu-8001);
  font-family: var(--inter-h41);
}

.container {
  width: 100%;
}


@media only screen and (max-width: 576px) {
  .heroSection{
    height: 764px;
  }
  .bgIcon {
    width: auto;
    height: 764px;
  }
  .tnhNng, .cnSd {
    padding: 8px;
    padding-top: 40px;
    padding-bottom: 40px;
    width: auto;
  }
  .mHnh {
    padding: 8px;
    padding-top: 85px;
  }
  .title {
    margin: 0;
    padding: 0;
    width: 100vw;
    gap: 30px;
  }
  .title1 {
    width: 100%;
  }
  .giiPhpTon {
    font-size: 36px;
    line-height: 30px;
    text-align: center;
    margin-top: 35px;
  }
  .polygonParent {
    width: auto;
    flex-direction: column;
  }
  .feature1 {
    margin: 8px;
    width: auto;
  }
  .feature9Parent {
    width: auto;
  }
  .angPhtTrinWrapper {
    left: auto;
    right: 8px;
  }
  .decor1Icon{
    top: -80px;
    left: auto;
    right: -25px;
    z-index: 0;
    width: 197px;
    height: auto;
  }
  .triosGiaiQuytCacVnWrapper {
    padding: 0;
    font-size: 14px;
  }
  .plan {
    width: 100%;
    padding: 0px;
    padding-top: 20px;
    gap: 15px;
  }
  .title2 {
    width: 80%;
    padding: 8px;
  }
  .giiPhpTon {
    line-height: 45px;
  }
  .triosGiaiQuyt {
    padding: 5%;
    font-size: 17px;
  }
  .container {
    display: none;
  }
}


@media only screen and (min-width: 576px) { 
  .containerMobile {
    display: none;
  }
}

