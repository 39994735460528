.groupChild {
  position: absolute;
  height: 67.37%;
  width: 100%;
  top: 14.82%;
  right: 0;
  bottom: 17.81%;
  left: 0;
  background-color: var(--brand-400-pri1);
}
.groupIcon,
.groupInner,
.groupItem {
  position: absolute;
  top: 14.82%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.groupItem {
  height: 100%;
  width: 87.01%;
  right: 6.49%;
  bottom: -14.82%;
  left: 6.49%;
  opacity: 0.5;
}
.groupIcon,
.groupInner {
  height: 75.75%;
  width: 65.97%;
  right: 17.01%;
  bottom: 9.43%;
  left: 17.01%;
}
.groupIcon {
  height: 42.16%;
  width: 77.08%;
  top: 24.45%;
  right: 8.98%;
  bottom: 33.38%;
  left: 13.93%;
}
.e2eEncryption1 {
  position: absolute;
  width: 31.51%;
  top: 19.31%;
  left: 35.16%;
  line-height: 56.36px;
  font-weight: 600;
  display: none;
}
.rectangleParent {
  position: absolute;
  height: 148.44%;
  width: 100%;
  top: -22%;
  right: 0;
  bottom: -26.44%;
  left: 0;
}
.e2eEncryption {
  position: relative;
  width: 100%;
  height: 50%;
  flex-shrink: 0;
  overflow: hidden;
}
.mHaU {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 32px;
}
.mHaUCuiE2eeWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.lPhngThc,
.readMore {
  align-self: stretch;
  position: relative;
  line-height: 24px;
}
.readMore {
  flex: 1;
  display: none;
  font-family: var(--open-sans-h5);
  color: var(--brand-400-pri1);
  text-align: left;
}
.lPhngThcMHaMChNParent {
  align-items: flex-start;
  gap: var(--gap-3xs);
  font-size: 80%;
  color: var(--neu-7001);
}
.e2eEncryptionParent,
.frameParent,
.lPhngThcMHaMChNParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frameParent {
  padding: var(--padding-sm) var(--padding-sm) var(--padding-lg);
  align-items: center;
  gap: var(--gap-4xs);
  text-align: center;
  font-size: var(--inter-sub-2-size);
  color: var(--neu-8001);
}
.e2eEncryptionParent {
  align-items: flex-start;
}
.advanceEncrytionStandard2,
.tech1 {
  width: 424px;
  flex-shrink: 0;
  overflow: hidden;
}
.tech1 {
  border-radius: var(--br-xl);
  background-color: var(--neu-001);
  box-shadow: 0 4px 16px rgba(0, 114, 201, 0.06);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--neu-001);
  height: 100%;
}
.advanceEncrytionStandard2 {
  position: relative;
  height: 250px;
  object-fit: cover;
}
.frameGroup,
.tech2 {
  flex-direction: column;
  align-items: center;
}
.frameGroup {
  align-self: stretch;
  display: flex;
  padding: var(--padding-sm) var(--padding-sm) var(--padding-lg);
  justify-content: flex-start;
  gap: var(--gap-4xs);
}
.tech2 {
  border-radius: var(--br-xl);
  background-color: var(--neu-001);
  box-shadow: 0 4px 16px rgba(0, 114, 201, 0.06);
  flex-shrink: 0;
  overflow: hidden;
  height: auto;
  width: 30%;
}
.tech1Parent, 
.tech1
.tech2,
.tech3 {
  display: flex;
  justify-content: center;
}
.tech3 {
  align-self: stretch;
  border-radius: var(--br-xl);
  background-color: var(--neu-001);
  box-shadow: 0 4px 16px rgba(0, 114, 201, 0.06);
  width: 424px;
  flex-shrink: 0;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
}
.tech1Parent {
  width: 70%;
  height: auto;
  flex-direction: row;
  gap: var(--gap-xs);
  z-index: 1;
  text-align: center;
  font-size: var(--inter-sub-2-size);
  color: var(--neu-8001);
  font-family: var(--inter-h41);
}

@media only screen and (max-width: 576px) {
  .tech1Parent {
    flex-direction: column;
    width: auto;
    height: auto;
    align-items: center;
  }
  .tech1, 
  .tech2, 
  .tech3 {
    height: auto;
    width: 80%;
  }
  .e2eEncryption {
    width: 100%;
  }
  .e2eEncryptionParent {
    align-items: center;
  }
  
}
