@import url("https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@500&display=swap");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --inter-h41: Inter;
  --open-sans-h5: "Open Sans";
  --be-vn-subtitle-small: "Be Vietnam Pro";

  /* font sizes */
  --be-vn-subtitle-small-size: 12px;
  --open-sans-body-s-size: 14px;
  --inter-body-m1-size: 16px;
  --open-sans-h5-size: 20px;
  --inter-sub-2-size: 24px;
  --inter-h41-size: 32px;
  --font-size-3xl: 32.87px;
  --open-sans-h3-size: 36px;
  --inter-h2-size: 54px;
  --font-size-6xl: 56px;
  --inter-h1-size: 68px;
  --font-size-8xl: 100px;

  /* Colors */
  --neu-001: #fff;
  --brand-500: #0a3d89;
  --neu-7001: #394f5f;
  --color-darkslategray-200: #344a5e;
  --neu-8001: #002b48;
  --brand-300: #6bd2f5;
  --brand-400-pri1: #008fe8;
  --semantic-error-500: #e60a0a;
  --neutral-500: #868d93;
  --color-gray-200: #000e18;
  --second-500: #0d16d3;
  --second-400: #4c54fa;
  --neu-100: #f7f7f8;
  --neu-200: #e9ecee;
  --color-gainsboro-100: #d9d9d9;
  --neu-300: #d5dadd;
  --second-200: #b7bafa;
  --color-blueviolet: #9747ff;
  --neu-600: #6c7d88;
  --color-aliceblue: #edf8ff;
  --semantic-error-100: #feeded;
  --semantic-error-400: #ee4c5e;
  --semantic-success-100: #e6f8ec;
  --semantic-success-400: #4dd077;
  --semantic-success-500: #00bc3c;
  --semantic-warning-100: #fff6d6;
  --semantic-warning-500: #ffc700;
  --brand-200: #ceecff;

  /* Gaps */
  --gap-10xs: 3.25px;
  --gap-9xs: 4px;
  --gap-8xs: 6px;
  --gap-7xs: 8px;
  --gap-6xs: 9px;
  --gap-5xs: 10px;
  --gap-4xs: 12px;
  --gap-3xs: 16px;
  --gap-2xs: 20px;
  --gap-xs: 24px;
  --gap-sm: 27px;
  --gap-md: 36px;
  --gap-lg: 40px;
  --gap-xl: 42px;
  --gap-2xl: 43px;
  --gap-3xl: 48px;
  --gap-4xl: 50px;
  --gap-5xl: 60px;
  --gap-6xl: 64px;
  --gap-7xl: 67px;
  --gap-8xl: 91px;
  --gap-9xl: 96px;
  --gap-10xl: 130px;

  /* Paddings */
  --padding-6xs: 4px;
  --padding-5xs: 8px;
  --padding-4xs: 10px;
  --padding-3xs: 12px;
  --padding-2xs: 16px;
  --padding-xs: 20px;
  --padding-sm: 24px;
  --padding-md: 30px;
  --padding-lg: 36px;
  --padding-xl: 48px;
  --padding-2xl: 60px;
  --padding-3xl: 100px;
  --padding-4xl: 120px;
  --padding-5xl: 200px;
  --padding-6xl: 300px;

  /* border radiuses */
  --br-8xs: 2px;
  --br-7xs: 3px;
  --br-6xs: 3.25px;
  --br-5xs: 3.9px;
  --br-4xs: 4px;
  --br-3xs: 5px;
  --br-2xs: 6px;
  --br-xs: 8px;
  --br-sm: 11.97px;
  --br-md: 12px;
  --br-lg: 14.16px;
  --br-xl: 16px;
  --br-2xl: 20px;
  --br-3xl: 24px;
  --br-4xl: 25px;
  --br-5xl: 30px;
  --br-6xl: 33px;
  --br-7xl: 40px;
  --br-8xl: 100px;
}
