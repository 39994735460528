a {
  text-decoration: none;
}

.image183Icon {
  position: relative;
  width: 93.99px;
  height: 100px;
  flex-shrink: 0;
  object-fit: cover;
}
.giiPhpTon {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 64px;
}
.triosGiaiQuyt {
  flex: 1;
  position: relative;
  line-height: 24px;
}
.title,
.triosGiaiQuytCacVnWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.triosGiaiQuytCacVnWrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-2xl);
  font-size: 35%;
}
.title {
  width: 90%;
  flex-direction: column;
  gap: var(--gap-2xs);
}
.chevronRightIcon {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}
.buttonDesktop,
.buttonDesktop1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.buttonDesktop1 {
  flex: 1;
  border-radius: var(--br-xs);
  border: 1.5px solid var(--neu-001);
  padding: var(--padding-2xs) var(--padding-lg);
  gap: var(--gap-7xs);
}
.buttonDesktop10{
  flex: 1;
  border-radius: var(--br-xs);
  border: 1.5px solid var(--neu-001);
  padding: var(--padding-2xs);
}
a .buttonDesktop1 ,.buttonDesktop10{
  line-height: 24px;
  color: rgba(255, 255, 255, 0.904);
  font-weight: bold;
  font-size: large;
}
.buttonDesktop1:hover , .buttonDesktop10:hover{
  color: rgba(0, 132, 255, 0.904);
  background-color: aliceblue;
}
.buttonDesktop {
  width: 178px;
  flex-shrink: 0;
  cursor: pointer;
}
.tiTrios1 {
  position: relative;
  line-height: 24px;
}
.buttonDesktop2 {
  border-radius: var(--br-xs);
  background-color: var(--neu-001);
  flex-direction: row;
  padding: var(--padding-2xs) var(--padding-lg);
  align-items: flex-start;
  gap: var(--gap-7xs);
  color: var(--brand-400-pri1);
}
.button,
.buttonDesktop2,
.content,
.titleParent {
  display: flex;
  justify-content: flex-start;
}
.button {
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-xs);
  font-size: var(--inter-body-m1-size);
}
.content,
.titleParent {
  flex-direction: column;
  align-items: center;
  gap: var(--gap-5xl);
}
.content {
  align-self: stretch;
  gap: var(--gap-xs);
  z-index: 0;
}
.giyPhpChild,
.giyPhpItem {
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 1494px;
  width: 223px;
  height: 216px;
  flex-shrink: 0;
  z-index: 0;
}
.giyPhpItem {
  left: 0;
  width: 500px;
  height: 1000px;
  opacity: 0.5;
  z-index: 0;
}
.giyPhp {
  align-self: stretch;
  background-color: var(--brand-400-pri1);
  display: flex;
  flex-direction: column;
  padding: var(--padding-3xl) 0;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-3xl);
  text-align: center;
  font-size: var(--inter-h2-size);
  color: var(--neu-001);
  font-family: var(--inter-h41);
  z-index: 2;
}

@media only screen and (max-width: 576px) { 
  .giyPhp {
    display: none;
  }
}
