a {
  text-decoration: none;
}
.logoTriosIcon {
  position: relative;
  width: 160px;
  height: 50px;
  flex-shrink: 0;
}
.giiThiu{
  position: relative;
  line-height: 24px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: large;
}
a.giiThiu:hover{
  color: rgba(0, 132, 255, 0.904);
}

.element_header_scroll{
  position: relative;
  line-height: 24px;
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: large;
}
a.element_header_scroll:hover{
  color: rgba(0, 132, 255, 0.904);
}

.chooseBtn {
  position: relative;
  line-height: 24px;
  text-decoration: none;
  color: rgba(0, 132, 255, 0.904);
  font-weight: bold;
  font-size: large;
}


.chevronDownIcon {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}
.giiThiuParent {
  color: var(--brand-400-pri1);
}
.frameParent,
.giiPhpParent,
.giiThiuParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.frameParent {
  width: 60%;
  align-items: flex-start;
  justify-content: space-between;
}
.buttonDesktop1 {
  border-radius: var(--br-xs);
  background-color: var(--brand-400-pri1);
  border: 1.5px solid var(--brand-400-pri1);
  padding: var(--padding-3xs) var(--padding-lg);
  gap: var(--gap-7xs);
  position: relative;
}
a .buttonDesktop1 {
  line-height: 24px;
  color: rgba(255, 255, 255, 0.904);
  font-weight: bold;
  font-size: large;
}
.buttonDesktop1:hover{
  color: rgba(0, 132, 255, 0.904);
  background-color: aliceblue;
}
.buttonDesktop,
.buttonDesktop1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.tingVit {
  position: relative;
  line-height: 24px;
  display: inline-block;
  width: 100px;
  flex-shrink: 0;
}
.vietnamIcon {
  border-radius: var(--br-8xs);
}
.navigationvariant3Icon,
.vietnamIcon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
}
.frameGroup {
  padding: 0 var(--padding-3xs);
  justify-content: flex-start;
  gap: var(--gap-6xs);
  text-align: right;
}
.buttonDesktopParent,
.content,
.frameGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.buttonDesktopParent {
  justify-content: flex-start;
  text-align: center;
}
.content {

  width: 80%;
  max-width: 1536px;
  justify-content: space-between;
  padding-left: calc(calc(50vw - 2304px)/2);
  padding-right: calc(calc(50vw - 2304px)/2);
}
.navigation,
.navigation1,
.navigation_scroll {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.navigation1 {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06);
  padding-top: 12px ;
  padding-bottom: 5px;
  width: 90%;
}
.navigation {
  position: fixed;
  top: 0;
  left: 0;
  align-items: flex-start;
  text-align: left;
  font-size: var(--inter-body-m1-size);
  width: 100%;
  font-family: var(--inter-h41);
  z-index: 4;
  transition: all ease-in-out .2s;
  background: transparent;
}
.navigation_scroll {
  position: fixed;
  top: 0;
  left: 0;
  align-items: flex-start;
  text-align: left;
  font-size: var(--inter-body-m1-size);
  color: var(--neu-001);
  width: 100%;
  font-family: var(--inter-h41);
  z-index: 4;
  transition: all ease-in-out .2s;
  background: transparent;
  color: black;
  background-color: aliceblue;
}


@media only screen and (max-width: 576px) {
  .frameParent, .content {
    display: none;
  }
  .buttonDesktopParent {
    display: none;
  }
  .logoTriosIcon{
    display: none;
  }
  .menuIcon {
    width: 30px;
    height: 30px;
    float: right;
    margin-top: 3%;
  }
  .navigation {
    display: block;
  }
  .navigation1 {
    padding: 12px 20px;
    display: block;
  }
}


@media only screen and (max-width: 992px) {
  .frameParent {
    display: none;
  }
}

@media only screen and (min-width: 576px){
  .menuIcon {
    display: none;
  }
  .logoTriosIconMobile {
    display: none;
  }
}


