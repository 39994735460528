.popupNgKTriosChild {
  position: absolute;
  margin: 0 !important;
  top: 117.91px;
  left: 72.5px;
  width: 647.5px;
  height: 518.09px;
  flex-shrink: 0;
  opacity: 1;
}
.logoTriosIcon {
  position: relative;
  width: 66px;
  height: 60px;
  flex-shrink: 0;
}
.giiPhpTon {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 64px;
}
.triosGiaiQuyt {
  flex: 1;
  position: relative;
  line-height: 24px;
}
.triosGiaiQuytCacVnWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 0 var(--padding-2xl);
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--inter-body-m1-size);
  color: var(--neu-7001);
}
.logoTriosParent,
.title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.title {
  width: 648px;
  align-items: flex-start;
  gap: var(--gap-2xs);
}
.logoTriosParent {
  align-items: center;
  gap: var(--gap-xs);
  z-index: 10;
}
.hVTn {
  position: relative;
  line-height: 22px;
  font-weight: 600;
}
.vuiLngNhp {
  position: relative;
  line-height: 24px;
}
.placeholder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.navigationvariant3Icon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
  opacity: 0;
}
.placeholderParent {
  align-self: stretch;
  border-radius: var(--br-2xs);
  background-color: var(--neu-001);
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-2xs);
  align-items: center;
  justify-content: space-between;
  font-size: var(--inter-body-m1-size);
  border: solid 1px;
}
.iconLeft {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.helperText {
  position: relative;
  line-height: 18px;
  font-weight: 500;
}
.helper,
.iconLeftParent {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.iconLeftParent {
  display: flex;
  gap: var(--gap-7xs);
}
.helper {
  align-self: stretch;
  display: none;
  font-size: var(--be-vn-subtitle-small-size);
  color: var(--neutral-500);
  font-family: var(--be-vn-subtitle-small);
}
.inputForm,
.inputFormParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.inputForm {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-7xs);
}
.inputFormParent {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-3xs);
}
.navigationvariant3Icon2 {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
}
.input,
.inputForm2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.inputForm2 {
  width: 648px;
  gap: var(--gap-7xs);
}
.input {
  gap: var(--gap-3xs);
}
.error {
  position: relative;
  font-size: var(--inter-body-m1-size);
  line-height: 24px;
  color: var(--semantic-error-500);
  text-align: center;
  display: none;
  align-items: center;
  justify-content: center;
  width: 648px;
}
.inputParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.downloadFillIcon {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}
.buttonDesktop,
.form {
  display: flex;
  justify-content: flex-start;
}
.buttonDesktop {
  border-radius: var(--br-xs);
  background-color: var(--brand-400-pri1);
  flex-direction: row;
  padding: var(--padding-2xs) var(--padding-lg);
  align-items: flex-start;
  gap: var(--gap-7xs);
  text-align: center;
  font-size: var(--inter-body-m1-size);
  color: var(--neu-001);
  cursor: pointer;
  border: none;
}
.form {
  width: 648px;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-md);
  z-index: 10;
  text-align: left;
  font-size: var(--open-sans-body-s-size);
}
.popupNgKTriosItem {
  position: absolute;
  margin: 0 !important;
  top: 50.95px;
  left: 41.43px;
  border-radius: var(--br-3xs);
  width: 34.65px;
  height: 34.65px;
  flex-shrink: 0;
  z-index: 10;
}
.closeIcon {
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  margin: 0 !important;
  top: 19.19px;
  right: 20px;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  z-index: 10;
  border: 1px solid gray;
  border-radius: 50%;
}
.popupNgKTrios {
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: var(--br-3xl);
  background-color: var(--neu-100);
  box-shadow: 0 4px 24px rgba(0, 44, 95, 0.1);
  display: flex;
  flex-direction: column;
  padding: var(--padding-2xl) var(--padding-lg);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  text-align: center;
  font-size: var(--inter-h2-size);
  color: var(--neu-8001);
  font-family: var(--inter-h41);
  z-index: 10;
  transform: translate(-50%, -50%);
  background: #f7f7f8;
}

@media only screen and (max-width: 576px) {
  .popupNgKTrios {
    height: 70%;
    width: 70%;
    gap: 5%;
  }
  .logoTriosParent,
  .form,
  .inputFormParent,
  .inputForm2 {
    width: 100%;
  }
  .logoTriosIcon {
    width: 20%;
    height: auto;
  }
  .logoTriosParent {
    height: 30%;
    gap: 5%;
  }
  .inputFormParent {
    flex-direction: column;
  }
  .title {
    width: 100%;
  }
  .giiPhpTon {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }
  .triosGiaiQuytCacVnWrapper,
  .triosGiaiQuyt {
    width: 100%;
    font-size: 20px;
    padding: 0;
  }
}
