a {
  text-decoration: none;
}
.sHuLm,
.traoIThng {
  align-self: stretch;
  position: relative;
}
.traoIThng {
  letter-spacing: -0.01em;
  line-height: 80px;
  padding-left: 16%;
  padding-right: 16%;
}
.sHuLm {
  line-height: 24px;
}
.des,
.header {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.des {
  padding: 10px;
  align-items: center;
  font-size: 30%;
}
.header {
  align-items: flex-start;
  gap: var(--gap-2xs);
  font-size: var(--inter-h1-size);
}
.tiTrios {
  position: relative;
  line-height: 24px;
}
a .buttonDesktop1 {
  line-height: 24px;
  color: rgba(255, 255, 255, 0.904);
  font-weight: bold;
  font-size: large;
}
.buttonDesktop1:hover{
  color: rgba(0, 132, 255, 0.904);
  background-color: aliceblue;
}
.chevronRightIcon {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}
.buttonDesktop1 {
  border-radius: var(--br-xs);
  background-color: var(--brand-400-pri1);
  border: 1.5px solid var(--brand-400-pri1);
  padding: var(--padding-2xs) var(--padding-lg);
  gap: var(--gap-7xs);
}
.buttonDesktop,
.buttonDesktop1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
}
.downloadIcon {
  position: relative;
  width: 180px;
  height: 52px;
  flex-shrink: 0;
  mix-blend-mode: normal;
}
.downloadParent,
.headerParent,
.triNghimTriosTrnParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.headerParent,
.triNghimTriosTrnParent {
  flex-direction: column;
  align-items: center;
}
.headerParent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  padding: var(--padding-3xl) 0;
  box-sizing: border-box;
  gap: var(--gap-5xl);
  text-align: center;
  font-size: var(--inter-body-m1-size);
  color: var(--neu-001);
  font-family: var(--inter-h41);
}

.headerPolicy {
  position: absolute;
  box-sizing: border-box;
  margin: 50px;
  padding: var(--padding-3xl) 0;
  gap: var(--gap-5xl);
  color: var(--neu-001);
  font-size: var(--inter-body-m1-size);
}

@media only screen and (max-width: 576px) {
  .headerParent {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    gap: 25px;
  }
  .headerPolicy {
    width: 100%;
    gap: 25px;
    margin: 10px;
  }

  .header, .des {
    width: 100%;
    align-self: center;
  }
  .traoIThng {
    font-size: 36px;
    line-height: 44px;
    margin: auto;
  }
  .sHuLm {
    align-items: center;
    gap: 4px;
    align-self: center;
    width: 50%;
    height: auto;
  }
  .buttonDesktop1 {
    padding: 10px 10px;
  }
  .downloadIcon {
    width: 163.5px;
    height: 52px;
    margin: 2%;
  }
  .downloadParent {
    gap: 10px;
  }
  
}