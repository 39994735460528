
  .tiTrios {
    position: relative;
    line-height: 24px;
  }
  .tiTrios:hover {
    color: rgb(0, 89, 255);
  }

  .tiTrios1 {
    position: relative;
    line-height: 24px;
    color: #fff;
  }

  .menu1,
  .vietnamIcon {
    position: relative;
    flex-shrink: 0;
  }
  .menu1 {
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    width: 100px;
  }
  .vietnamIcon {
    border-radius: var(--br-7xs);
    width: 20px;
    height: 20px;
    overflow: hidden;
    display: none;
  }
  .menuParent {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-xs);
  }
  .menuIcon {
    position: relative;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    overflow: hidden;
  }
  .frameGroup {
    padding: 0 var(--padding-xs);
    justify-content: flex-start;
    gap: var(--gap-sm);
    text-align: right;
  }
  .buttonDesktopParent,
  .content,
  .frameGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .buttonDesktopParent {
    justify-content: flex-start;
    gap: var(--gap-xl);
    text-align: center;
  }
  .content {
    flex: 1;
    justify-content: space-between;
  }
  .header1,
  .navigationMb {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .navigationMb {
    width: 375px;
    flex-direction: row;
    padding: var(--padding-xs) var(--padding-sm);
    box-sizing: border-box;
    text-align: left;
    font-size: var(--inter-sub-3-size);
    color: var(--neutral-100);
    font-family: var(--inter-body-s1);
  }
  .header1 {
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: column;
    font-size: var(--font-size-sm);
    color: var(--neutral-600);
    font-family: var(--font-sf-ui-display);
  }
  .hero {
    position: relative;
    width: 375px;
    height: 702px;
    flex-shrink: 0;
  }
  .tnhNngMbChild,
  .tnhNngMbItem {
    position: absolute;
    margin: 0 !important;
    top: -18px;
    left: 203.43px;
    width: 288px;
    height: 288px;
    flex-shrink: 0;
    z-index: 0;
  }
  .tnhNngMbItem {
    top: 72px;
    left: 0;
    width: 375px;
    height: 38px;
    opacity: 0.5;
    z-index: 1;
  }
  .giiPhpTon {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.01em;
    line-height: 40px;
  }
  .triosGiaiQuyt {
    flex: 1;
    position: relative;
    line-height: 24px;
  }
  .title,
  .triosGiaiQuytCacVnWrapper {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .triosGiaiQuytCacVnWrapper {
    flex-direction: row;
    font-size: var(--inter-sub-3-size);
    color: var(--neu-700);
  }
  .title {
    flex-direction: column;
    gap: var(--gap-3xl);
    z-index: 2;
  }
  .illusIcon,
  .ilus11Icon {
    position: relative;
    flex-shrink: 0;
  }
  .illusIcon {
    width: 1040px;
    height: 600px;
    display: none;
    z-index: 3;
  }
  .ilus11Icon {
    width: 70px;
    height: 70px;
  }
  .ilus11Wrapper {
    border-radius: var(--br-9xl);
    background-color: var(--brand-300);
    display: flex;
    flex-direction: row;
    padding: var(--padding-md);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .sHuLm1 {
    letter-spacing: -0.01em;
    line-height: 32px;
  }
  .readMore,
  .sHuLm1,
  .sHuLm2,
  .triosUcTrin {
    align-self: stretch;
    position: relative;
  }
  .sHuLm2 {
    flex: 1;
    font-size: var(--inter-h3-size);
    line-height: 48px;
    display: none;
    font-family: var(--open-sans-h5);
  }
  .readMore,
  .triosUcTrin {
    line-height: 24px;
  }
  .readMore {
    display: none;
    font-family: var(--open-sans-h5);
    color: var(--brand-400-pri);
    text-align: left;
    height: 24px;
    flex-shrink: 0;
  }
  .feature1,
  .frameContainer,
  .triosUcTrinKhaiTrenParent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .triosUcTrinKhaiTrenParent {
    align-self: stretch;
    align-items: flex-start;
    gap: var(--gap-2xl);
    font-size: var(--inter-sub-3-size);
    color: var(--neu-700);
  }
  .feature1,
  .frameContainer {
    align-items: center;
  }
  .frameContainer {
    align-self: stretch;
    gap: var(--gap-xl);
  }
  .feature1 {
    border-radius: var(--br-5xl);
    background-color: var(--neutral-100);
    box-shadow: 0 4px 20px rgba(0, 75, 133, 0.08);
    width: 343px;
    padding: var(--padding-xl) var(--padding-lg);
    box-sizing: border-box;
    gap: var(--gap-4xl);
  }
  .feature1Parent {
    align-items: flex-start;
  }
  .feature11,
  .feature1Group,
  .feature1Parent,
  .frame {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: var(--gap-4xl);
  }
  .feature11 {
    align-self: stretch;
    border-radius: var(--br-5xl);
    background-color: var(--neutral-100);
    box-shadow: 0 4px 20px rgba(0, 75, 133, 0.08);
    padding: var(--padding-xl) var(--padding-lg);
    align-items: center;
  }
  .feature1Group,
  .frame {
    width: 343px;
    align-items: flex-start;
  }
  .frame {
    width: 375px;
    align-items: center;
    z-index: 4;
    font-size: var(--inter-sub-2-size);
  }
  .tnhNngMbInner {
    position: absolute;
    margin: 0 !important;
    top: 314.14px;
    left: 24px;
    border-radius: var(--br-md);
    width: 52.05px;
    height: 52.05px;
    flex-shrink: 0;
    opacity: 0.3;
    z-index: 5;
  }
  .tnhNngMb {
    background-color: var(--neu-100);
    width: 375px;
    display: flex;
    flex-direction: column;
    padding: var(--padding-4xl) var(--padding-sm);
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    gap: var(--gap-8xl);
    font-size: var(--inter-h4-size);
    color: var(--neu-800);
  }
  .popupBgChild,
  .triosHomepage {
    position: absolute;
    top: 0;
    left: 0;
  }
  .triosHomepage {
    background-color: var(--neutral-100);
    height: 812px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .popupBgChild {
    height: 100%;
    width: 100%;
    right: 0;
    bottom: 0;
    background-color: var(--neu-800);
    opacity: 0.2;
  }
  .decor2Icon,
  .popupBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 375px;
    height: 812px;
  }
  .decor2Icon {
    margin: 0 !important;
    top: -27px;
    left: 147px;
    width: 183px;
    height: 183px;
    flex-shrink: 0;
    opacity: 0.5;
    z-index: 0;
  }
  .menu3 {
    align-self: stretch;
    position: relative;
    font-size: 50px;
    letter-spacing: -0.01em;
    line-height: 40px;
    z-index: 1;
    color: rgb(0, 132, 255);
  }
  .vietnamIcon1 {
    position: relative;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    overflow: hidden;
    opacity: 0;
  }
  .menu11 {
    align-self: stretch;
    border-bottom: 0.5px solid var(--neu-200);
    display: flex;
    flex-direction: row;
    padding: 5%;
    align-items: center;
    justify-content: space-between;
  }
  .options {
    width: 311px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .radioButtonChild,
  .radioButtonItem {
    position: absolute;
    border-radius: var(--br-8xl);
  }
  .radioButtonChild {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid var(--brand-400-pri);
    box-sizing: border-box;
  }
  .radioButtonItem {
    height: 44.44%;
    width: 44.44%;
    top: 27.77%;
    right: 27.78%;
    bottom: 27.78%;
    left: 27.78%;
    background-color: var(--brand-400-pri);
  }

  .buttonMobile1,
  .content1,
  .menu2 {
    display: flex;
    justify-content: flex-start;
  }
  .buttonMobile1 {
    border-radius: var(--br-xs);
    background-color: var(--brand-400-pri1);
    border: 1.5px solid var(--brand-400-pri1);
    padding: var(--padding-3xs) var(--padding-lg);
    gap: var(--gap-7xs);
    position: relative;
  }
  .menu2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: var(--br-5xl);
    background-color: var(--neutral-100);
    box-shadow: 0 4px 4px rgba(0, 60, 105, 0.1);
    width: 343px;
    flex-direction: column;
    padding: var(--padding-lg) var(--padding-sm);
    box-sizing: border-box;
    align-items: flex-end;
    gap: var(--gap-4xl);
    text-align: left;
    color: var(--brand-5001);
    background-color: #fff;
    max-width: 100%;
    max-height: 90%;
  }
  .menu {
    position: relative;
    width: 100%;
    height: 812px;
    text-align: center;
    font-size: var(--inter-sub-3-size);
    color: var(--neutral-100);
    font-family: var(--inter-body-s1);
  }
  